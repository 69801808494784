import { Backdrop, Box, Button, Checkbox, CircularProgress, Divider, Fade, FormControl, FormControlLabel, FormGroup, Modal, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import Breadcrumb from "../../components/shared-breadcrumb";
import useStores from "../../hooks/use-stores"
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import { navigate } from "gatsby";
import { GetCurrentConvertedDateTime, isBrowser, ProdEnvCheck, questionType } from "../../constants/options";
import { toJS } from "mobx";
import { MODAL_STYLE } from "../../constants/style";
import { t } from "i18next";

const OnExamPage = observer(({ location }) => {
  const { examStore, rootStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [startTiming, setStartTiming] = useState<boolean>(false);
  const [examQuestions, setExamQuestions] = useState<any>([]);
  const [knowlegePointLabels, setKnowlegePointLabels] = useState<any>([]);
  const [answerList, setAnswerList] = useState<any>([]);
  const [refreshFunction, setRefreshFunction] = useState<any>([]);

  const onPrepareExamEnv = (response: any, resume?: boolean) => {
    let respondQuestions = resume ? response.questionList : response.questions;
    setExamQuestions(respondQuestions);
    // Select distinct knowledge
    if (respondQuestions[0].knowledgePoint !== "") {
      setKnowlegePointLabels([...new Set(respondQuestions.map(q => q.knowledgePoint))]);
    } else {
      setKnowlegePointLabels([""]);
    }
    let tempAnswerList = [];
    respondQuestions.map((q: any) => tempAnswerList.push({
      questionId: q.id,
      response: q.type === questionType.multiple ? [] : "",
      fullMark: q.fullMark
    }));
    let timerValue = 0;
    if (resume) {
      // Schedule completed questions
      for (var question of JSON.parse(localStorage.ON_EXAM)) {
        tempAnswerList.find(q => q.questionId == question.questionId)
          .response = question.response;
      }
      // Schedule time left
      let startTime = response.startTime;
      let currentTime = GetCurrentConvertedDateTime();
      let timeLeft = Math.abs(new Date(startTime).valueOf() - new Date(currentTime).valueOf());
      timerValue = Math.round((location.state.timeLimit * 60 * 1000 - timeLeft) / 60000);
    } else {
      localStorage.ON_EXAM_ATTEMPT = response.attemptId;
      timerValue = location.state.timeLimit;
    }
    localStorage.ON_EXAM = JSON.stringify(tempAnswerList);
    setAnswerList(tempAnswerList);
    setTimer(timerValue);
    setStartTiming(true);
  }

  useEffect(() => {
    if (location.state && location.state.examId && location.state.timeLimit) {
      if (ProdEnvCheck([localStorage.ON_EXAM, localStorage.ON_EXAM_ATTEMPT])) {
        let onExamReq = JSON.parse(localStorage.ON_EXAM);
        examStore.resumeExam({
          examId: location.state.examId, attemptId: localStorage.ON_EXAM_ATTEMPT, questionIdList: onExamReq.map(q => q.questionId)
        }).then((res) => onPrepareExamEnv(res, true))
          .catch(() => navigate("/exam"));
      } else {
        setTimer(location.state.timeLimit);
        examStore.startExam(location.state.examId)
          .then((res) => onPrepareExamEnv(res))
          .catch(() => navigate("/exam"));
      }
    }
  }, [location]);

  useEffect(() => {
    // Make sure the timer countdown starting after the question is finished render
    if (startTiming && examQuestions.length > 0 && timer > 0) {
      setTimeout(() => {
        let tempTimer = timer - 1;
        setTimer(tempTimer);
      }, 100 * 60);
    }
    if (startTiming && examQuestions.length > 0 && timer <= 0) {
      onSubmitExam();
    }
  }, [startTiming, timer]);

  const onChangeSingleChoice = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let tempAnswerList = answerList;
    tempAnswerList.find(a => a.questionId == id).response = (event.target as HTMLInputElement).value;
    console.log(tempAnswerList, "Wdndm");
    localStorage.ON_EXAM = JSON.stringify(tempAnswerList);
    setAnswerList(tempAnswerList);
    setRefreshFunction([]);
  };

  const onChangeMultiChoice = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let tempAnswerList = answerList;
    let targetObj = tempAnswerList.find(a => a.questionId == id).response;
    if (targetObj.includes(+event.target.name)) {
      let index = targetObj.indexOf(+event.target.name);
      targetObj.splice(index, 1);
    } else {
      targetObj.push(+event.target.name);
      tempAnswerList.find(a => a.questionId == id).response = targetObj;
    }
    console.log(tempAnswerList.find(a => a.questionId == id).response, "Wdndm");
    localStorage.ON_EXAM = JSON.stringify(tempAnswerList);
    setAnswerList(tempAnswerList);
    setRefreshFunction([]);
  };

  const onSubmitExam = () => {
    let response: any[] = [];
    for (var answer of answerList) {
      let currentResponse: any = {
        questionId: answer.questionId,
        response: answer.response,
        fullMark: answer.fullMark
      };
      if (Array.isArray(answer.response)) {
        let responseListToString = "";
        if (answer.response.length != 0) {
          for (var r of answer.response) responseListToString = responseListToString.concat(r + ",");
          responseListToString = responseListToString.substring(0, responseListToString.length - 1);
        }
        currentResponse.response = responseListToString;
      }
      response.push(currentResponse);
    }
    let req = {
      endTime: GetCurrentConvertedDateTime(),
      response: response,
      attemptId: localStorage.ON_EXAM_ATTEMPT,
    }
    examStore.submitExam(req)
      .then(() => {
        rootStore.notify(t('EXAM_SUBMITTED'), 'success');
        localStorage.removeItem("ON_EXAM");
        localStorage.removeItem("ON_EXAM_ATTEMPT");
        navigate("/exam");
      }).catch(() => rootStore.notify(t('EXAM_SUBMIT_FAILED'), 'error'));
  }

  return (
    <Layout pageName="Dashboard">
      {isBrowser && location.state && location.state.examId && location.state.timeLimit &&
        // <Paper elevation={1} className="p-4">
        <>
          <div className="flex justify-between mt-6">
            {/* <Typography variant="h5" fontWeight={'bold'} marginBottom={2}>My Exam</Typography> */}
          </div>
          {examStore.loading || examQuestions.length == 0 ?
            <div className="text-center py-20">
              <CircularProgress className="text-theme" />
              <Typography>{t('LOADING_EXAM')}...</Typography>
            </div>
            :
            <>
              <div className="grid grid-cols-7">
                {knowlegePointLabels.length > 0 &&
                  knowlegePointLabels.map((kpl: string, kplIndex: number) => (
                    <>
                      {/* Knowledge Area */}
                      <div>
                        <div className="border-r-8 border-theme pr-4 bg-white py-2 rounded-l-lg mb-4">
                          <Typography
                            variant="h5"
                            // marginBottom={2}
                            // sx={{ textDecoration: "underline" }}
                            textAlign="right"
                          >
                            {kpl === "" ? "Exam" : kpl}
                          </Typography>
                        </div>
                      </div>

                      {/* Question Area */}
                      <div className="col-span-5 border-l border-r px-4">
                        {examQuestions.filter(q => q.knowledgePoint === kpl)
                          .map((question: any, index: number) => (
                            <div key={`on_exam_question_${index}`} className="bg-white p-4 rounded-lg mb-4">
                              <Typography variant="h6" marginBottom={1}>
                                {index + 1}. {question.detail} <span className="text-sm">({question.fullMark} {question.fullMark > 1 ? "marks" : "mark"})</span>
                              </Typography>
                              {question.type === questionType.single ?
                                <FormControl key={`question_option_${index}`}>
                                  <RadioGroup
                                    aria-labelledby="controlled-radio-buttons-group"
                                    name="single-multiple-choice-group"
                                    value={answerList.find(q => q.questionId == question.id) ? parseInt(answerList.find(q => q.questionId == question.id).response) : 0}
                                    onChange={(event) => onChangeSingleChoice(event, question.id)}
                                  >
                                    {question.option.map((o: any, index: number) => (
                                      <FormControlLabel
                                        value={o.id}
                                        control={<Radio />}
                                        label={<Typography variant="body2" color={"#626262"}>{o.detail}</Typography>}
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                                : question.type === questionType.multiple ?
                                  <FormGroup key={`question_option_${index}`}>
                                    {question.option.map((o: any, index: number) => (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              answerList.find(q => q.questionId == question.id) &&
                                                answerList.find(q => q.questionId == question.id).response.includes(o.id) ? true : false}
                                            onChange={(event) => onChangeMultiChoice(event, question.id)}
                                          />}
                                        label={<Typography variant="body2" color={"#626262"}>{o.detail}</Typography>}
                                        name={o.id}
                                      />
                                    ))}
                                  </FormGroup>
                                  : question.type === questionType.fillInBlank &&
                                  <TextField
                                    inputProps={{ 'aria-label': 'description' }}
                                    sx={{ marginBottom: 2, color: "#626262" }}
                                    value={ProdEnvCheck([localStorage.ON_EXAM]) &&
                                      JSON.parse(localStorage.ON_EXAM).find(q => q.questionId == question.id) &&
                                      JSON.parse(localStorage.ON_EXAM).find(q => q.questionId == question.id).response}
                                    onChange={(event) => {
                                      let tempAnswerList = answerList;
                                      tempAnswerList.find(a => a.questionId == question.id).response = event.target.value;
                                      console.log(event.target.value, "Wdndm");
                                      localStorage.ON_EXAM = JSON.stringify(tempAnswerList);
                                      setAnswerList(tempAnswerList);
                                      setRefreshFunction([]);
                                    }}
                                    size="small"
                                  />
                              }
                              {/* {question.id != examQuestions[examQuestions.length - 1].id && <Divider sx={{ marginBottom: 1.5 }} />} */}
                            </div>
                          ))}
                      </div>

                      {/* Timer (only show one timer) */}
                      {kplIndex == 0 &&
                        <Box>
                          <div className="sticky top-1/3 ml-4">
                            <div className="absolute border-2 bg-white border-theme rounded-lg p-4 shadow-lg">
                              <Typography marginTop={1} marginBottom={1} textAlign="center">{t('TIME_LEFT')}</Typography>
                              <div className="relative">
                                <CircularProgress
                                  variant="determinate"
                                  value={timer}
                                  size={90}
                                  className={timer < 10 ? "text-red-500" : timer < 20 ? "text-highlight" : "text-theme"}
                                />
                                <div
                                  className={`absolute top-1/2 w-full text-center ${timer < 10 ? "text-red-500" : "text-black"}`}
                                  style={{ transform: "translate(0, -50%)", marginTop: -3 }}
                                >
                                  <Typography fontWeight={"light"}>{timer}{t('MINS_B')}</Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Box>
                      }
                    </>
                  ))}
              </div>
              <div className="text-right py-4 bg-white rounded-lg">
                <Button
                  variant="outlined"
                  sx={{ marginRight: 2, fontSize: 16 }}
                  onClick={() => setShowModal(true)}
                >
                  {t('SUBMIT_EXAM')}
                </Button>
              </div>
            </>
          }

          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={showModal}>
              <Box sx={MODAL_STYLE}>
                <div className="bg-theme text-white p-4" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
                  <Typography variant={"h6"}>
                    {t('SUBMIT_EXAM_CHECK')}
                  </Typography>
                </div>
                {answerList.length > 0 && answerList.filter(a => a.response === "" || a.response.length == 0).length > 0 &&
                  <div className="p-4">
                    <Typography className="text-red-500" sx={{ marginBottom: 1 }}>
                      {t('QUESTIONS_NOT_ANSWERED')}
                    </Typography>
                    <div className="border max-h-96 overflow-auto p-1">
                      {answerList.filter(a => a.response === "" || a.response.length == 0).map((a: any, index: number) => (
                        examQuestions.filter(eq => eq.id == a.questionId).map((eq: any) => (
                          <Typography my={1}>{index + 1}: {eq.detail}</Typography>
                        ))
                      ))}
                    </div>
                  </div>}

                <div className="text-right p-4">
                  <Button
                    variant="outlined"
                    onClick={onSubmitExam}
                  >
                    {t('SUBMIT_NOW')}
                  </Button>
                </div>
              </Box>
            </Fade>
          </Modal>
          {/* // </Paper> */}
        </>}
    </Layout >
  )
});

export default OnExamPage;
